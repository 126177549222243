html {
  box-sizing: border-box;
  font-size: 16px;
  height: calc(100vh - calc(100vh - 100%));
  width: 100vw;
  overflow: hidden;
}

:root {
  --main-bg-color: white;
  --secondary-bg-color: hsla(220, 14%, 16%, 0.9);
  --main-text-color: hsl(220, 14%, 16%);
  --secondary-text-color: hsl(20, 16%, 93%);
  --tertiary-text-color: #e72a6a;
}

body {
  width: 100%;
  height: 100%;
  background-color: white;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
figure,
section,
article,
header,
nav,
main {
  margin: 0;
  padding: 0;
  font-weight: 450;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  color: var(--main-text-color);
}

h2,
h3,
h4 {
  color: var(--secondary-text-color);
}

p {
  line-height: 1.3;
  font-family: 'Open Sans', sans-serif;
}

*:focus {
  outline: 2px dotted var(--main-text-color);
}

*:focus:not(:focus-visible) {
  outline: none;
}

*:focus:not(:-moz-focusring) {
  outline: none;
}

*:focus-visible {
  outline: 2px dotted var(--main-text-color);
}

*:-moz-focusring {
  outline: 2px dotted var(--main-text-color);
}

a:link,
a:visited,
a:active {
  color: var(--secondary-text-color);
  text-decoration: none;
}

a.nav-item:link,
a.nav-item:visited,
a.nav-item:active {
  color: var(--main-text-color);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

.current {
  font-weight: 600;
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

.current:hover {
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

.tab-nav-item.current {
  text-decoration: underline solid 2px;
  text-underline-offset: 5px;
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

#en,
#de {
  cursor: pointer;
}

#en:hover,
#de:hover {
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

.modal-container {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  padding: 1em 2.25em;
  height: 7.594em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
}

h1 {
  font-size: 2.25em;
  font-weight: 600;
  letter-spacing: 0.296em;
  text-transform: uppercase;
  margin-right: 1em;
  cursor: pointer;
  z-index: 1;
}

h2 {
  font-size: 1.4em;
  font-weight: 600;
}

h3 {
  margin: 0.296em 0;
}

nav {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.444em 1.25em 0 0;
  align-items: flex-end;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

nav.media-nav {
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.667em 0 0 0;
}

figure {
  display: flex;
  justify-content: center;
}

.menu-button,
.closer-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-button {
  height: 2em;
  width: 2em;
}

.closer-button {
  height: 1.1em;
  width: 1.1em;
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
}

.component-closer {
  height: 1.1em;
  width: 1.1em;
  color: white;
}

.component-closer:hover {
  transform: scale(1.2);
}

.burger {
  height: 1.9em;
  width: 1.9em;
  color: var(--main-text-color);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.burger.open {
  transform: rotate(90deg);
}

.burger.closed {
  transform: rotate(0);
}

.closer {
  height: 1.7em;
  width: 1.7em;
  color: var(--main-text-color);
}

.burger:hover,
.closer:hover {
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

.burger.show,
.closer.show {
  display: static;
}

.burger.hide,
.closer.hide {
  display: none;
}

.menu-container {
  position: fixed;
  right: 1em;
  top: 1em;
  bottom: 1em;
  max-width: 300px;
  overflow: hidden;
}

.menu {
  display: flex;
  padding-top: 5.063em;
  padding-left: 2.25em;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: hsla(20, 16%, 93%, 50%);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.menu.open {
  transform: translate(0%, 0);
}

.menu.closed {
  transform: translate(100%, 0);
}

.nav-item {
  color: var(--main-text-color);
  margin: 1em 0;
}

.other-language {
  font-weight: 300;
}

.current-language {
  font-weight: 600;
}

.links {
  height: 50%;
  max-height: 20em;
  position: fixed;
  left: 2.25em;
  bottom: 3.375em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.icon-container,
.icon-container > img {
  height: 24px;
  width: 24px;
}

.icon {
  color: var(--main-text-color);
}

.icon:hover {
  transform: scale(1.25);
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

.pink:hover {
  color: var(--tertiary-text-color);
  animation: colorchange 4s infinite alternate linear;
}

.soundcloud {
  width: 32px;
}

.youtube {
  width: 28px;
}

.allcaps {
  text-transform: uppercase;
}

.home {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  border: 1em solid;
  border-color: #fc82e5;
  animation: borderchange 4s infinite alternate linear;
}

.home > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about {
  position: relative;
  height: 70vh;
  width: 75vw;
  max-width: 700px;
  padding: 3.375em 2.25em 2.25em 2.25em;
  text-align: center;
  background-color: var(--secondary-bg-color);
  border: 1em solid;
  border-color: #fc82e5;
  animation: borderchange 4s infinite alternate linear;
}

.about-text {
  width: 100%;
  height: 100%;
  padding-right: 1em;
  text-align: justify;
  hyphens: auto;
  overflow-y: auto;
  scrollbar-width: 0.444em;
  scrollbar-color: var(--secondary-text-color) transparent;
}

.about-text p {
  line-height: 1.5;
  margin-bottom: 1em;
  font-size: 1.2em;
  font-weight: 600;
  color: var(--secondary-text-color);
}

.about-text::-webkit-scrollbar {
  width: 0.444em;
}

.about-text::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid darkgray;
  border-radius: 5px;
}

.about-text::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

.about-text::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.media {
  position: relative;
  height: 70vh;
  width: 65vw;
  background-color: var(--secondary-bg-color);
  border: 1em solid;
  border-color: #fc82e5;
  animation: borderchange 4s infinite alternate linear;
  overflow: auto;
}

.video-container,
.links-container {
  height: 100%;
  padding: 2.25em;
}

.links-container p {
  margin-bottom: 0.667em;
}

.links-container a {
  text-decoration: underline dotted;
  text-underline-offset: 3px;
}

.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 2.25em;
}

.iframe-container:last-child {
  margin-bottom: 0;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.events {
  position: relative;
  padding: 3.375em;
  max-height: 60%;
  max-width: 60%;
  max-width: 700px;
  background-color: #fc82e5;
  animation: backgroundchange 4s infinite alternate linear;
  overflow-y: auto;
}

.event-container {
  margin-top: 1em;
}

.eventlist {
  margin-top: 0.667em;
  overflow-y: auto;
  max-height: calc(60vh - 10em);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.eventlist::-webkit-scrollbar {
  display: none;
}

.italic {
  font-style: italic;
}

.contact {
  position: relative;
  padding: 1.5em 2.25em 1em 2.25em;
  max-width: 420px;
  max-height: 75vh;
  text-align: right;
  overflow: auto;
  background-color: #fc82e5;
  animation: backgroundchange 4s infinite alternate linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
  color: var(--secondary-text-color);
}

.contact::-webkit-scrollbar {
  display: none;
}

form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

form p {
  margin-bottom: 1em;
}

input,
textarea {
  margin-top: 0.444em;
  width: 20em;
  padding: 0.444em;
  font-family: 'Open Sans', sans-serif;
  color: var(--main-text-color);
  font-size: 1em;
}

input,
button {
  height: 2.25em;
}

textarea {
  height: 7.594em;
}

label {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 650;
}

button {
  cursor: pointer;
}

.button {
  font-family: 'Montserrat', sans-serif;
  color: var(--secondary-text-color);
  font-size: 1em;
  width: 7.594em;
  background-color: var(--main-text-color);
  border: 1px solid;
  border-color: var(--secondary-text-color);
}

.button:hover {
  border: 2px solid;
}

.center {
  text-align: center;
}

.center h4 {
  line-height: 1.4;
}

.ok {
  margin: 1em 0 0.444em 0;
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0;
}

.bold {
  font-weight: 600;
}

.impressum {
  position: relative;
  max-height: 60vh;
  max-width: 550px;
  max-width: 80vw;
  padding: 2em 2.25em 1em 2.25em;
  background-color: var(--secondary-bg-color);
  color: var(--secondary-text-color);
  overflow: auto;
}

.impressum > h2 {
  text-align: center;
  margin-bottom: 1em;
}

.impressum > h4 {
  margin-bottom: 1em;
}

@keyframes borderchange {
  0% {
    border-color: #fc82e5;
  }
  50% {
    border-color: #e72a6a;
  }
  100% {
    border-color: #d2518a;
  }
}

@keyframes colorchange {
  0% {
    color: #fc82e5;
  }
  50% {
    color: #e72a6a;
  }
  100% {
    color: #d2518a;
  }
}

@keyframes backgroundchange {
  0% {
    background-color: #fc82e5;
  }
  50% {
    background-color: #e72a6a;
  }
  100% {
    background-color: #d2518a;
  }
}

@keyframes shadowchange {
  0% {
    box-shadow: 0px 0px 0px 0.296em #fc82e5;
  }
  50% {
    box-shadow: 0px 0px 0px 0.296em #e72a6a;
  }
  100% {
    box-shadow: 0px 0px 0px 0.296em #d2518a;
  }
}

/* MEDIA QUERIES */
@media only screen and (orientation: portrait) and (min-width: 500px) and (max-width: 800px) {
  h1 {
    margin-top: 1em;
  }
  .menu {
    padding-left: 1.5em;
  }
}

@media only screen and (max-width: 500px) {
  html {
    font-size: 14px;
  }
  .modal-container {
    width: 100%;
  }
  h1 {
    font-size: 1.5em;
    letter-spacing: 0.198em;
  }
  p,
  .about-text p {
    font-size: 1.1em;
  }
  header {
    padding: 2em 2em 1.5em 2em;
  }
  nav {
    padding-right: 1em;
  }
  .menu {
    padding-left: 1.5em;
  }
  .links {
    left: 1.7em;
    bottom: 2.25em;
  }
  .about {
    padding: 2.25em 2em;
  }
  .about-text {
    margin-top: 1em;
  }
  .impressum {
    padding: 1.5em 1em 1em 1em;
  }
  .about,
  .media {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }
  .events,
  .contact {
    max-height: calc(100% - 5em);
    width: calc(100% - 5em);
    max-width: calc(100% - 5em);
    color: var(--secondary-text-color);
  }
  .events {
    padding: 2.5em;
  }
  .events h2 {
    font-size: 1.25em;
  }
  input,
  textarea {
    width: 100%;
  }
  .eventlist {
    max-height: 100%;
  }
  .media-container {
    display: flex;
    flex-direction: column;
    padding-top: 3.25em;
  }
  .media-box {
    width: 75vw;
    margin-bottom: 2em;
  }
}

@media only screen and (max-width: 300px) {
  h1 {
    font-size: 1.3em;
  }
}
